import axios from "axios";

const instance = axios.create({
	// baseURL: "https://api.bruningsistemasteste.com.br/api/v1",
	// baseURL: "https://voatro.herokuapp.com/api/v1",
	// baseURL: "https://api.bruningsistemasteste.com.br/api/v1",
	// baseURL: "http://localhost:3001/teste/v1",
	// baseURL: "https://api-homologacao.bruningsistemasteste.com.br/teste/v1",
	/*rota dev*/
	baseURL: "https://api-homologacao.bruningsistemasteste.com.br/teste/v1",
});

export default instance;